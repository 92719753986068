import { css } from "@emotion/css";

export function Footer() {
    return (
        <div className="flex justify-center border-t-[1px] border-t-[#DBDBDB] px-10 py-6 md:px-2 md:py-4 ">
            <div className="font-avenirn text-[12px] font-[500] text-grey-900 md:hidden">Frameworks supported</div>
            <div className="ml-6 flex items-center gap-7 md:ml-0">
                <img
                    src="https://freelogopng.com/images/all_img/1681142315open-ai-logo.png"
                    className={logoCSS}
                    height={"20px"}
                    width={"50px"}
                />
                <img src="https://i.imgur.com/JJzHYzv.png" className={logoCSS} height={"20px"} width={"80px"} />
                <img
                    src="https://cdn.prod.website-files.com/66cf2bfc3ed15b02da0ca770/66d07240057721394308addd_Logo%20(1).svg"
                    className={logoCSS}
                    height={"20px"}
                    width={"42px"}
                />
                <img src="https://i.imgur.com/7vdbOz8.png" className={`${logoCSS} md:hidden`} height={"20px"} width={"100px"} />
            </div>
        </div>
    );
}

const logoCSS = css`
    filter: grayscale(20%);
    :hover {
        filter: none;
    }
`;
