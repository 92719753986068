"use client";

import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import * as Sentry from "@sentry/nextjs";
import router from "next/router";
import { useEffect } from "react";
import { postGoogleLogin } from "~/dataProcessor/api/api";
import { Button } from "~/design-system/atom/button";
import { GoogleIcon } from "~/ui/constants/svg/homepage";
import { getCookie, setCookie } from "~/utils/cookie";
import { getCurrentClientSideHost } from "~/utils/global";
import { ANALYTICS } from "~/utils/tracking";

const noop = () => {};

declare namespace global {
    interface Window {
        google: any;
    }
}

const GOOGLE_CLIENT_ID =
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || "920687958684-v45uflh67rhr3rou62kcffrmd9lajasi.apps.googleusercontent.com";

const GoogleSignInButton = () => {
    const postLoginCallback = async (
        data: {
            jwt?: string;
            authCode?: string;
        },
        type: "auth-code" | "jwt",
    ) => {
        try {
            const { email } = await postGoogleLogin(data, type);

            if (email) {
                await ANALYTICS.identify(email, {
                    name: email,
                    email: email,
                });
            }

            setCookie("isLoggedIn", "true", 30);
            setCookie("composio_email", email || "", 30);
            Sentry.setUser({
                email: email,
            });
            router.push("/dashboard");
            // You can add more logic here, such as redirecting the user or storing the tokens
        } catch {
            alert("Some error occurred while trying to log you in. Please try after sometime");
        }
    };

    const login = useGoogleLogin({
        onSuccess: (credentialResponse: any) => {
            postLoginCallback({ authCode: credentialResponse.code }, "auth-code");
        },
        onError: noop,
        flow: "auth-code",
        ux_mode: "redirect",
        select_account: true,
        redirect_uri: `${getCurrentClientSideHost()}/provider_login/google`,
    });

    useEffect(() => {
        requestAnimationFrame(() => {
            if (!getCookie("isLoggedIn")) {
                // @ts-ignore
                window?.google?.accounts?.id?.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: (credentialResponse: any) => {
                        postLoginCallback({ jwt: credentialResponse.credential }, "jwt");
                    },
                    cancel_on_tap_outside: false,
                });
                // @ts-ignore
                window?.google?.accounts?.id?.prompt(() => {});
            }
        });
    }, [login]);

    return (
        <Button
            title="Login with Google"
            variant={"default"}
            className="text-black w-full bg-[#fff] text-[14px] tracking-[.1px] shadow-[0px_0px_0px_1.5px_rgba(90,28,170,0.12)] hover:brightness-95"
            onClick={() => login()}
        >
            <div className="text-black flex items-center gap-2 text-[13.5px] font-[600] leading-[13px]">
                <GoogleIcon className="h-4 w-4 opacity-100" />
                <span className="pt-[1px]">Continue with Google</span>
            </div>
        </Button>
    );
};

const GoogleLoginComponent = () => {
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} onScriptLoadError={() => {}}>
            <GoogleSignInButton />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginComponent;
