import { CheckCircleHero } from "~/ui/constants/svg/homepage";

export function LeftSection() {
    const features = [
        {
            heading: "Extensive Tool Support & Framework Compatibility",
            description:
                "Seamlessly link your agent with Gmail, Salesforce, GitHub, File Manager, Shell with frameworks like CrewAI, Langchain, Gemini, and Claude.",
        },
        {
            heading: "Enhanced Agent Reliability & Security",
            description: "Boost reliability by 30% with expert agentic tools, ensuring SOC2 compliant, secure and private data encryption.",
        },
        {
            heading: "Streamlined Authentication Management",
            description: "Unified dashboard for managing authentication, supporting OAuth, API Key, and 7 other auth methods.",
        },
    ];

    return (
        <div className="flex w-full flex-col flex-col items-center pt-11 md:hidden text-black-300">
            <div className="font-avenirn text-[16px] font-[700]">Agentic Integration Platform</div>
            <div className="mt-10 flex flex-col gap-9 px-10">
                {features.map((feature, index) => (
                    <div className="flex gap-3 pr-5" key={index}>
                        <CheckCircleHero height={24} width={24} className="min-w-[fit-content]" />
                        <div>
                            <div className="font-avenirn text-[14px] font-[600]">{feature.heading}</div>
                            <div className="mt-1 text-[13px] font-[400] font-normal leading-[21px] tracking-[0.35px] text-grey-700">
                                {feature.description}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
